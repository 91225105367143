<template>
  <div id="content" class="container">
    <div class="row">
      <div class="col-12">
        <a
          class="back"
          @click="this.$router.push({ path: this.$store.state.search_back })"
          >Retour</a
        >
        <h1>Contacts</h1>
      </div>
    </div>
    <div class="row cards">
      <div class="col-12 col-md-6 col-xl-4">
        <div class="content_card">
          <h2>Capitol, Polydor, Vinyl Collector, Dose</h2>
          <div class="contact_items">
            <ContactItem :contact="this.contacts[3]"></ContactItem>
            <ContactItem :contact="this.contacts[0]"></ContactItem>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-xl-4">
        <div class="content_card">
          <h2>
            Panthéon, Decca, Barclay, Phonogram, Island Def Jam, Virgin,
            Carthage, Everything Jazz, UMS
          </h2>
          <div class="contact_items">
            <ContactItem :contact="this.contacts[1]"></ContactItem>
            <ContactItem :contact="this.contacts[5]"></ContactItem>
          </div>
        </div>
      </div>
      <div class="col-12 offset-md-4 col-md-4 offset-xl-0 col-xl-3 claire">
        <div class="content_card">
          <div class="contact_items">
            <ContactItem :contact="this.contacts[4]"></ContactItem>
          </div>
        </div>
      </div>
    </div>
    <div class="row activists">
      <h2>CRM Activists : Référents CRM au sein des équipes/labels</h2>
      <div class="col-12 activists-content">
        <div
          v-for="(activist, index) in activists"
          :key="index"
          class="activist"
        >
          <a :href="'mailto:' + activist.email">{{ activist.name }}</a>
          <span> - {{ activist.poste }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContactItem from "@/components/ContactItem.vue";

export default {
  name: "Contact",
  data() {
    return {
      contacts: [
        {
          img: "pierre-charles.jpg",
          name: "Pierre-Charles Guy Sorba",
          poste: "Assistant Chef de projet CRM",
          email: "pierrecharles.guysorba@umusic.com",
        },
        {
          img: "marina.jpg",
          name: "Marina Wong",
          poste: "Cheffe de projet CRM",
          email: "marina.wong@umusic.com",
        },
        {
          img: "cloe.jpg",
          name: "Cloé Florville",
          poste: "Cheffe de projet CRM",
          email: "cloe.florville@umusic.com",
        },
        {
          img: "eva.jpg",
          name: "Eva Marcelat",
          poste: "Cheffe de projet CRM",
          email: "eva.marcelat@umusic.com",
        },
        {
          img: "claire.jpg",
          name: "Claire Cavallini",
          poste: "Directrice Data & Développement",
          email: "claire.cavallini@umusic.com",
        },
        {
          img: "enzo.jpg",
          name: "Enzo Hernando",
          poste: "Assistant Chef de projet CRM",
          email: "enzo.hernando@umusic.com",
        },
      ],
      activists: [
        {
          name: "Selim ABDI",
          email: "selim.abdi@umusic.com",
          poste: "Chef de projet DA Junior - AURA",
        },
        {
          name: "Bryan Bismuth",
          email: "bryan.bismuth@umusic.com",
          poste: "Chef de projet marketing - AURA",
        },
        {
          name: "Marjory Leproux",
          email: "marjory.leproux@umusic.com",
          poste: " Cheffe de projet DA Junior - AURA",
        },
        {
          name: "Camelya Bouida",
          email: "camelya.bouida@umusic.com",
          poste: "Cheffe de projet DA Junior - AURA",
        },
        {
          name: "Amine Farsi",
          email: "amine.farsi@umusic.com",
          poste: "Directeur Label - AURA",
        },
        {
          name: "Achille Nedati Wehr",
          email: "achille.nedatiwehr@umusic.com",
          poste: "Chef de projet DA Junior - AURA",
        },
        {
          name: "Laurence Ferracci",
          email: "laurence.ferracci@umusic.com",
          poste: "Cheffe de projet - Barclay",
        },
        {
          name: "Roselaine Boudjellal",
          email: "roselaine.boudjellal@umusic.com",
          poste: "Cheffe de projet - Capitol",
        },
        {
          name: "Shania Boum",
          email: "shania.boum@umusic.com",
          poste: "Assistante et chargée de prod - Carthage",
        },
        {
          name: "Alexandre Bin",
          email: "alexandre.bin@umusic.com",
          poste: "Chef de projet DA  - Carthage",
        },
        {
          name: "Margot Pietri",
          email: "margot.pietri@umusic.com",
          poste: "Cheffe de projet - Carthage",
        },
        {
          name: "Nadège Boulay",
          email: "nadege.boulay@umusic.com",
          poste: "Responsable contenu - Carthage",
        },
        {
          name: "Louis Da Costa",
          email: "louis.dacosta@umusic.com",
          poste: "Chargé marketing - D2F",
        },
        {
          name: "Julie Goussu",
          email: "julie.goussu@umusic.com",
          poste: "Chargée e-marketing e-commerce - D2F",
        },
        {
          name: "Aymeric Gaudelet",
          email: "aymeric.gaudelet@umusic.com",
          poste: "Chef de projet - D2F",
        },
        {
          name: "Thomas Lescan Du Plessix Orsel",
          email: "thomas.duplessix@umusic.com",
          poste: "Chef de projet - D2F",
        },
        {
          name: "Karine Luntadila",
          email: "karine.luntadila@umusic.com",
          poste: "Cheffe de projet - D2F",
        },
        {
          name: "Sebastien Eyzat",
          email: "sebastien.eyzat@umusic.com",
          poste: "Responsable marketing - D2F",
        },
        {
          name: "Julie Mycke",
          email: "julie.mycke@umusic.com",
          poste: "Cheffe de projet - Decca",
        },
        {
          name: "Lisa Lefebvre",
          email: "lisa.lefebvre@umusic.com",
          poste: "Cheffe de projet  - Decca",
        },
        {
          name: "Anais Hallier",
          email: "anais.hallier@umusic.com",
          poste: "Responsable marketing  - Decca",
        },
        {
          name: "Marie-Anne Chrétien",
          email: "marieanne.chretien@umusic.com",
          poste:
            "Responsable opérations digitales Panthéon/Decca/Barclay/Phonogram",
        },
        {
          name: "Romain Leclerc",
          email: "romain.leclercq@umusic.com",
          poste: "Chef de Projet - Island Def Jam",
        },
        {
          name: "Jordan Reutenauer",
          email: "jordan.reutenauer@umusic.com",
          poste: "Chef de projet  - Island Def Jam",
        },
        {
          name: "Emilie Butel",
          email: "emilie.butel@umusic.com",
          poste: "Cheffe de projet - Island Def Jam",
        },
        {
          name: "Angèle Baron",
          email: "angele.baron@umusic.com",
          poste: "Cheffe de projet - Island Def Jam",
        },
        {
          name: "Chloé Le Rille",
          email: "chloe.lerille@umusic.com",
          poste: "Cheffe de projet - Island Def Jam",
        },
        {
          name: "Romain Constant",
          email: "romain.constant@umusic.com",
          poste: "Directeur marketing national - Island Def Jam",
        },
        {
          name: "Simon Fraudeau",
          email: "simon.fraudeau@umusic.com",
          poste: "Directeur marketing national - Island Def Jam",
        },
        {
          name: "Lea Aguesse",
          email: "lea.aguesse@umusic.com",
          poste: "Cheffe de projet - Panthéon",
        },
        {
          name: "Laure Herouart",
          email: "laure.herouart@umusic.com",
          poste: "Cheffe de projet - Panthéon",
        },
        {
          name: "Margaux Bervas",
          email: "margaux.bervas@umusic.com",
          poste: "Cheffe de projet - Phonogram",
        },
        {
          name: "Levannah Azeroual",
          email: "levannah.azeroual@umusic.com",
          poste: "Cheffe de projet - Phonogram",
        },
        {
          name: "Bérangère Valles",
          email: "berangere.valles@umusic.com",
          poste: "Responsable marketing - Phonogram",
        },
        {
          name: "Adrien Henry",
          email: "adrien.henry@umusic.com",
          poste: "Chef de projet - Polydor",
        },
        {
          name: "Tom Poinssonnet",
          email: "tom.poinssonnet@umusic.com",
          poste: "Chef de projet - Polydor",
        },
        {
          name: "Sofiane Zeggaoui",
          email: "sofiane.zeggaoui@umusic.com",
          poste: "Chef de projet - Polydor",
        },
        {
          name: "Julien Deshayes",
          email: "julien.deshayes@umusic.com",
          poste: "Chef de projet - Polydor",
        },
        {
          name: "Jose Angulo",
          email: "jose.angulo@umusic.com",
          poste: "Chef de projet Export - Polydor",
        },
        {
          name: "Lea Leclair",
          email: "lea.leclair@umusic.com",
          poste: "Cheffe de projet - Polydor",
        },
        {
          name: "Carole Larue",
          email: "carole.larue@umusic.com",
          poste: "Cheffe de projet - Polydor",
        },
        {
          name: "Marie Charlotte Marguerite",
          email: "mariecharlotte.marguerite@umusic.com",
          poste: "Cheffe de projet - Polydor",
        },
        {
          name: "Benjamin Manaut",
          email: "benjamin.manaut@umusic.com",
          poste: "Directeur marketing - Polydor",
        },
        {
          name: "Dorian Bouchez",
          email: "dorian.bouchez@umusic.com",
          poste: "Chef de projet - Romance",
        },
        {
          name: "Alperen Aslan",
          email: "alperen.aslan2@umusic.com",
          poste: "Chef de projet digital - Romance",
        },
        {
          name: "Pierre Rimbaud",
          email: "pierre.rimbaud@romance-musique.com",
          poste: "Responsable marketing - Romance",
        },
        {
          name: "Jordane Gombault",
          email: "jordane.gombault@umusic.com",
          poste: "Directeur Digital marketing",
        },
        {
          name: "Angelina Paolini",
          email: "angelina.paolini@umusic.com",
          poste: "Responsable innovation et équipe stratégie digitale",
        },
        {
          name: "Leo Carrion",
          email: "leo.carrion@umusic.com",
          poste: "Responsable innovation",
        },
        {
          name: "Charles Combarel",
          email: "charles.combarel@umusic.com",
          poste: "Responsable marketing",
        },
        {
          name: "Charlotte Remoleux",
          email: "charlotte.remoleux@umusic.com",
          poste: "Responsable Marketing Digital & Streaming Manager France",
        },
        {
          name: "Mareva Jesel",
          email: "mareva.jesel@umusic.com",
          poste: "Responsable planning",
        },
        {
          name: "Sebastien Defrenne",
          email: "sebastien.defrenne@umusic.com",
          poste: "Responsable technique",
        },
        {
          name: "Martin Camille",
          email: "martin.camille@umusic.com",
          poste: "Chargée de communication - Vertigo",
        },
        {
          name: "François Enjalbert",
          email: "francois.enjalbert@umusic.com",
          poste: "Chef de projet - Virgin",
        },
        {
          name: "Ryan Lavoine",
          email: "ryan.lavoine@umusic.com",
          poste: "Chef de projet - Virgin",
        },
        {
          name: "Hugo Cabanne",
          email: "hugo.cabanne@umusic.com",
          poste: "Chef de projet et DA - Virgin",
        },
        {
          name: "Alice Galopet",
          email: "alice.galopet@umusic.com",
          poste: "Cheffe de projet - Virgin",
        },
        {
          name: "Claire Tate",
          email: "claire.tate@umusic.com",
          poste: "Cheffe de projet Virgin - Virgin",
        },
        {
          name: "Marie Cadio",
          email: "marie.cadio@umusic.com",
          poste: "Cheffe de projet Virgin - Virgin",
        },
        {
          name: "Alice Morchedzadeh",
          email: "alice.morchedzadeh@umusic.com",
          poste: "Directrice marketing local - Virgin",
        },
        {
          name: "Bourgeois Mambu",
          email: "bourgeois.mambu@umusic.com",
          poste: "Chargé éditorial - Dose Rap",
        },
        {
          name: "Michele Marcolungo",
          email: "michele.marcolungo@umusic.com",
          poste:
            "Chargé éditorial - Rap City / C'était mieux avant / Le son des darons",
        },
        {
          name: "Mélanie Fiton",
          email: "melanie.fiton@umusic.com",
          poste: "Chargée de développement & contenus",
        },
        {
          name: "Camille Deutschmann",
          email: "camille.deutschmann@umusic.com",
          poste: "Chargée éditoriale - Rush / Trop bien",
        },
        {
          name: "Hugo Reberat",
          email: "hugo.reberat@umusic.com",
          poste: "Chef de projet - Paid media",
        },
        {
          name: "Benoit Darcy",
          email: "benoit.darcy@umusic.com",
          poste: "Directeur Département Advertising & Insight Services",
        },
        {
          name: "Mickael Roux",
          email: "mickael.roux@umusic.com",
          poste: "Responsable achat média",
        },
      ],
    };
  },
  components: {
    ContactItem,
  },
  mounted() {
    this.$store.state.app_id = "contact";
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$store.state.search_back = from.fullPath;
    });
  },
};
</script>

<style lang="scss">
#contact {
  #content {
    padding-top: 180px;

    & > div:first-child {
      background: url("../assets/img/contact-background.svg") no-repeat bottom
        right;
    }
  }

  h1 {
    font-weight: 900;
    line-height: 103%;
    color: $blue-umf;
    margin-bottom: 40px;
  }

  .cards {
    .content_card {
      //display: inline-block;
      background-color: #eeeded;
      margin-bottom: 16px;
      padding: 20px;
      border-radius: 30px;

      h2 {
        display: block;
        font-size: 2rem;
        font-weight: 700;
        color: $blue-umf;
        margin-bottom: 15px;
      }
    }

    .claire {
      .contact_card {
        width: 100%;
      }
    }
  }

  .activists {
    background-color: #ededed;
    color: $blue-umf;
    padding: 33px;

    h2 {
      margin-bottom: 20px;
    }

    .activists-content {
      background-color: #ffffff;
      border-radius: 30px;
      padding: 26px 32px;
      font-weight: 400;
      font-size: 1.5rem;
      line-height: 133%;

      .activist {
        margin-bottom: 10px;
      }

      a {
        font-weight: 500;
        font-size: 1.7rem;
        color: $blue-umf;
        line-height: 140%;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  #contact {
    #content {
      & > div:first-child {
        background-size: contain;
        margin-bottom: 20px;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  #contact {
    .contact_card {
      display: flex;

      .img {
        width: 96px;
        height: auto;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  #contact {
    .contact_card {
      .infos {
        //height: calc(100% - 230px);
      }
    }

    .activists {
      border-radius: 30px;
      padding: 50px;

      .activists-content {
        padding: 38px 78px;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  #contact {
    #content {
      & > div:first-child {
        padding-bottom: 40px;
      }
    }

    .contact_items {
      display: flex;
    }

    h1 {
      margin-bottom: 100px;
    }

    .cards {
      position: relative;
      top: -20px;
      h2 {
        min-height: 3lh;
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  #contact {
    .claire .contact_card {
      padding-top: 85px;
    }
  }
}
</style>
